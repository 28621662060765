<footer class="footer">
  <div class="container">
    <div class="footer-copyright-container">
      <span>© 2000-2021 Coldwell Banker Real Estate LLC.</span>
    </div>
    <div class="footer-menu-container">
      <ul>
        <li><a>Terms of Use</a></li>
        <li><a>Privacy Policy</a></li>
      </ul>
    </div>
  </div>
</footer>
