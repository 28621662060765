<div class="header">
  <div class="header-menu-icon">
    <mat-sidenav-container class="sidenav-container">
      <mat-sidenav-content>
        <button mat-button aria-label="Menu icon" (click)="sidenav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
  <div class="header-brand">
    <img src="../../../../assets/imgs/logo.png" />
  </div>
  <div class="header-profile">
    <button
      mat-button
      [matMenuTriggerFor]="menu"
      [disableRipple]="true"
      aria-label="Profile dropdown menu"
    >
      <span *ngIf="!agentDetail.agent.photoUrl" class="candidate-short-name">
        {{ agentInitials }}
      </span>
      <span *ngIf="agentDetail.agent.photoUrl" class="header-profile-img">
        <img [src]="agentDetail.agent.photoUrl" />
      </span>
      <span class="header-profile-title hidden-md">{{ fullName }}</span>

      <span class="header-profile-dropdown hidden-md">
        <mat-icon>arrow_drop_down</mat-icon>
      </span>
    </button>
    <mat-menu #menu="matMenu" xPosition="before">
      <button mat-menu-item>
        <!-- <mat-icon>dialpad</mat-icon> -->
        <span>Logout</span>
      </button>
    </mat-menu>
  </div>
</div>
<mat-sidenav #sidenav disableClose (keydown.escape)="close()">
  <ul class="left-nav-wrapper-menus">
    <li>
      <a href="javascript:void(0)" (click)="close()">
        <mat-icon>dashboard</mat-icon><span>Dashboard</span>
      </a>
    </li>

    <li [routerLink]="['/createreferral']">
      <a href="javascript:void(0)" (click)="close()">
        <mat-icon>add_circle</mat-icon><span>Create Referral</span>
      </a>
    </li>

    <li>
      <a href="javascript:void(0)" (click)="close()">
        <mat-icon>edit</mat-icon><span>Drafts</span>
      </a>
    </li>
  </ul>
</mat-sidenav>
