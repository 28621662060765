import { Component, OnInit } from '@angular/core';
import { AgentDetails } from 'src/app/core/models/AgentDetails';
import { AgentDetailService } from 'src/app/core/services/agent-detail.service';

@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.scss']
})
export class ReferralComponent implements OnInit {
  agentDetail: AgentDetails;

  agentDetailsMock: AgentDetails = {
    'agent': {
      'id': '5ba29705-e79e-4518-94d3-d399fce3a057',
      'name': 'Rebecca Carlson',
      'firstName': 'Rebecca',
      'lastName': 'Carlson',
      'photoUrl': 'https://openapi.azureedge.net/88EF9294-70B6-4E95-9A3E-8CEB3D64AA3F',
      'emailAddress': 'rebeccacarlson@cbbain.com',
      'businessPhone': '2063228711',
      'phoneNumber': '2063228711',
      'officeId': '25345413-35b8-11d7-a2c7-00b0d0494558',
      'officeRFGId': '0007',
      'companyId': '29cf8289-3115-11d7-a2c7-00b0d0494558',
      'companyRFGId': '110036',
      'companyName': 'Coldwell Banker BAIN',
      'cityName': 'Seattle',
      'stateCode': 'WA',
      'postalCode': '98102',
      'brokerId': '1',
      'totalSaleVolume': '$ -'
    },
    'channelAccess': {
      'mobileAccess': true,
      'webAccess': false
    },
    'roleHierarchy': '',
    'productSupportDetail': []
  };

  constructor(
    private agentDetailSvc: AgentDetailService
  ) { }

  ngOnInit() {
    // To uncomment when actual service happening
    // this.agentDetailSvc.getLoggedInAgent().subscribe((agent: AgentDetails) => {
    //   if (agent) {
    //     this.agentDetail = agent;
    //     this.agentDetailSvc.updateLoggedInAgentDetails(agent);
    //   }
    // });
    this.agentDetail = { ...this.agentDetailsMock };
    this.agentDetailSvc.updateLoggedInAgentDetails(this.agentDetailsMock);
  }

}
