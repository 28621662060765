import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReferralComponent } from './referral.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CreateReferralComponent } from './create-referral/create-referral.component';


const routes: Routes = [
  {
    path: '',
    component: ReferralComponent,
    children: [
      {
        path: 'createreferral',
        component: CreateReferralComponent,
      },
      { path: '', redirectTo: 'createreferral', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReferralRoutingModule { }
