import { NgModule, InjectionToken } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot } from '@angular/router';
import { LogoutComponent } from './core/components/logout/logout.component';
import { ExternRouteComponent } from './core/components/extern-route/extern-route.component';

const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');

const routes: Routes = [
  { path: '', redirectTo: '', pathMatch: 'full' },
  {
    path: '',
    loadChildren: () => import('./public/referral/referral.module').then(m => m.ReferralModule)
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'externalRedirect',
    resolve: {
      url: externalUrlProvider
    },
    component: ExternRouteComponent
  }
];

@NgModule({
  providers: [
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalURL = route.paramMap.get('externalUrl');
        window.location.replace(externalURL);
      }
    }
  ],
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
