import { Component, Input, OnInit } from '@angular/core';
import { AgentDetails } from 'src/app/core/models/AgentDetails';

@Component({
  selector: 'app-left-navigation',
  templateUrl: './left-navigation.component.html',
  styleUrls: ['./left-navigation.component.scss'],
})
export class LeftNavigationComponent implements OnInit {
  @Input() agentDetail: AgentDetails;
  fullName: string;

  constructor() { }

  ngOnInit(): void {
    this.fullName = `${this.agentDetail.agent.firstName} ${this.agentDetail.agent.lastName}`;
  }
}
