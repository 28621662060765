import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-extern-route',
  templateUrl: './extern-route.component.html',
  styleUrls: ['./extern-route.component.scss']
})
export class ExternRouteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
