<div class="left-nav-wrapper">
  <div class="profile">
    <p class="profile-title">{{ fullName }}</p>
    <p class="profile-description">
      Brokerage: {{ agentDetail.agent.companyName }}
    </p>
  </div>
  <ul class="left-nav-wrapper-menus">
    <li>
      <a> <mat-icon>dashboard</mat-icon><span>Dashboard</span> </a>
    </li>
    <li>
      <a routerLink="/createreferral" class="active">
        <mat-icon>add_circle</mat-icon><span>Create Referral</span>
      </a>
    </li>
    <li>
      <a> <mat-icon>edit</mat-icon><span>Drafts</span> </a>
    </li>
  </ul>
</div>
