import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { ReferralRoutingModule } from './referral-routing.module';
import { ReferralComponent } from './referral.component';
import { HeaderComponent } from 'src/app/core/components/header/header.component';
import { FooterComponent } from 'src/app/core/components/footer/footer.component';
import { MaterialModule } from 'src/app/material/material.module';
import { MatListModule } from '@angular/material/list';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CreateReferralComponent } from './create-referral/create-referral.component';
import { LeftNavigationComponent } from '../left-navigation/left-navigation.component';
import { PhoneFormatDirective } from 'src/app/core/directives/phone-format.directive';
import { ZipFormatDirective } from 'src/app/core/directives/zip-format.directive';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ReferralComponent,
    DashboardComponent,
    CreateReferralComponent,
    LeftNavigationComponent,
    PhoneFormatDirective,
    ZipFormatDirective
  ],
  imports: [
    CommonModule,
    ReferralRoutingModule,
    MaterialModule,
    MatListModule,
    MatExpansionModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    HttpClientModule,
  ],
  providers: [
    CookieService,
    CurrencyPipe
  ],
  entryComponents: [],
  exports: [
    PhoneFormatDirective,
    ZipFormatDirective
  ]
})
export class ReferralModule { }
