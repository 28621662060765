import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { map, catchError } from 'rxjs/operators';
import { urlType } from '../models/urlType';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    protected baseClient: BaseClientService,
    private readonly logSvc: RemoteLoggingService,
  ) { }

  isUserLoggedIn() {
    // return this.baseClient.getById<any>('/user/context', '', urlType.accessmgmt).pipe(
    //   map(r => r.body),
    //   catchError((err, source) => {
    //     const empty: any = null;
    //     this.logSvc.logError(err);
    //     return of(empty);
    //   })
    // );
  }
}
