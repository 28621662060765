<section class="agent-form-section">
  <h4 class="title-header">Create Referral</h4>
  <div>

    <div class="form-wrapper">
      <form [formGroup]="createReferralForm">
        <div formGroupName="customerInformation" class="form-container">
          <div class="form-heading">
            <h5>Customer Information</h5>
            <span class="required-fields">* Required Fields</span>
          </div>

          <div class="row">
            <div class="input-field-form">
              <mat-form-field appearance="outline" floatLabel="always" class="input-field">
                <mat-label><span>*</span> First Name</mat-label>
                <input matInput type="text" formControlName="customerFirstName" maxLength="30" />
                <mat-error *ngIf="
                    createReferralForm.get('customerInformation')['controls'][
                      'customerFirstName'
                    ].errors?.required
                  ">
                  Please enter First Name.
                </mat-error>
                <mat-error *ngIf="
                    createReferralForm.get('customerInformation')['controls'][
                      'customerFirstName'
                    ].errors?.pattern
                  ">
                  Please use only letters, a space, and -' characters.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="input-field-form">
              <mat-form-field appearance="outline" floatLabel="always" class="input-field">
                <mat-label><span>*</span> Last Name</mat-label>
                <input matInput type="text" formControlName="customerLastName" maxLength="30" />
                <mat-error *ngIf="
                    createReferralForm.get('customerInformation')['controls'][
                      'customerLastName'
                    ].errors?.required
                  ">
                  Please enter Last Name.
                </mat-error>
                <mat-error *ngIf="
                    createReferralForm.get('customerInformation')['controls'][
                      'customerLastName'
                    ].errors?.pattern
                  ">
                  Please use only letters, a space, and -' characters.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="input-field-form">
              <mat-form-field appearance="outline" floatLabel="always" class="input-field">
                <mat-label><span>*</span> Email</mat-label>
                <input matInput type="text" formControlName="customerEmail" maxLength="50" />
                <mat-error>Please enter a valid email address.</mat-error>
              </mat-form-field>
            </div>
            <div class="input-field-form mobile-field">
              <mat-form-field appearance="outline" floatLabel="always" class="input-phone-field">
                <mat-label><span>*</span> Phone</mat-label>
                <input matInput type="text" formControlName="customerPhone" phoneFormat maxlength="14" />
                <mat-error *ngIf="
                    createReferralForm.get('customerInformation')['controls'][
                      'customerPhone'
                    ].errors?.required
                  ">
                  Please enter Phone.</mat-error>
                <mat-error *ngIf="
                    createReferralForm.get('customerInformation')['controls'][
                      'customerPhone'
                    ].errors?.minLength
                  ">
                  Please enter a valid Phone.</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" floatLabel="always" class="input-mobile-field">
                <mat-select [value]="
                    createReferralForm.get('customerInformation')['controls'][
                      'customerPhoneType'
                    ].value
                  ">
                  <mat-option value="mobile">Mobile</mat-option>
                  <mat-option value="home">Home</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="input-field-form">
              <mat-form-field appearance="outline" floatLabel="always" class="address-input-field">
                <mat-label><span>*</span> Street Address</mat-label>
                <input matInput type="text" formControlName="customerStreetAddress" maxLength="72" />
                <mat-error>Please enter Mailing Street Address.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="input-field-form">
              <mat-form-field appearance="outline" floatLabel="always" class="input-field">
                <mat-label><span>*</span> City</mat-label>
                <input matInput type="text" formControlName="customerCity" maxLength="50" />
                <mat-error>Please enter City.</mat-error>
              </mat-form-field>
            </div>
            <div class="input-field-form">
              <mat-form-field appearance="outline" floatLabel="always" class="state-zip-input-field">
                <mat-label><span>*</span> State</mat-label>
                <input type="text" aria-label="Number" matInput formControlName="customerState"
                  [matAutocomplete]="customerInfoAutoComplete" />
                <mat-autocomplete #customerInfoAutoComplete="matAutocomplete">
                  <mat-option *ngFor="
                      let state of filteredStatesForCustomerInformation | async
                    " [value]="state.shortName">
                    {{ state.name }}
                  </mat-option>
                </mat-autocomplete>
                <mat-icon matSuffix class="clickable" *ngIf="!customerInfoAutoComplete.isOpen"
                  (click)="openCloseAutoCompletePanel($event)">arrow_drop_down
                </mat-icon>
                <mat-icon matSuffix class="clickable" *ngIf="customerInfoAutoComplete.isOpen"
                  (click)="openCloseAutoCompletePanel($event)">arrow_drop_up
                </mat-icon>
                <mat-error>Please enter State.</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" floatLabel="always" class="state-zip-input-field">
                <mat-label><span>*</span> Zip Code</mat-label>
                <input matInput type="text" formControlName="customerZipCode" zipFormat />
                <mat-error *ngIf="
                    createReferralForm.get('customerInformation')['controls'][
                      'customerZipCode'
                    ].errors?.required
                  ">
                  Please enter Zip.</mat-error>
                <mat-error *ngIf="
                    !createReferralForm.get('customerInformation')['controls'][
                      'customerZipCode'
                    ].errors?.required &&
                    createReferralForm.get('customerInformation')['controls'][
                      'customerZipCode'
                    ].errors?.zipError
                  ">
                  Please enter a valid Zip.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="input-field-form form-question">
              <mat-label><span>*</span> Is your customer buying, selling or
                both?</mat-label>
            </div>
          </div>
          <div class="row">
            <div class="input-field-form">
              <div>
                <button mat-stroked-button type="button" [ngClass]="
                    createReferralForm.get('customerInformation')['controls'][
                      'customerPreference'
                    ].value === 'buying'
                      ? 'customerPreference-select'
                      : 'customerPreference-unselect'
                  " (click)="updateCustomerPreference('buying')" class="mat-button-field">
                  Buying
                </button>
                <button mat-stroked-button type="button" [ngClass]="
                    createReferralForm.get('customerInformation')['controls'][
                      'customerPreference'
                    ].value === 'selling'
                      ? 'customerPreference-select'
                      : 'customerPreference-unselect'
                  " (click)="updateCustomerPreference('selling')" class="mat-button-field">
                  Selling
                </button>
                <button mat-stroked-button type="button" [ngClass]="
                    createReferralForm.get('customerInformation')['controls'][
                      'customerPreference'
                    ].value === 'both'
                      ? 'customerPreference-select'
                      : 'customerPreference-unselect'
                  " (click)="updateCustomerPreference('both')" class="mat-button-field">
                  Both
                </button>
              </div>
            </div>
          </div>
        </div>

        <div formGroupName="referringAgentInformation" class="form-container">
          <div class="form-heading">
            <h5>Your Information</h5>
            <span class="required-fields">* Required Fields</span>
          </div>
          <div class="row">
            <div class="input-field-form">
              <mat-form-field appearance="outline" floatLabel="always" class="input-field">
                <mat-label><span>*</span> First Name</mat-label>
                <input matInput type="text" formControlName="referringAgentFirstName" maxLength="30" />
                <mat-error
                  *ngIf="createReferralForm.get('referringAgentInformation')['controls']['referringAgentFirstName'].errors?.required">
                  Please enter First Name.
                </mat-error>
                <mat-error
                  *ngIf="createReferralForm.get('referringAgentInformation')['controls']['referringAgentFirstName'].errors?.pattern">
                  Please use only letters, a space, and -' characters.
                </mat-error>
              </mat-form-field>
            </div>
            <div class="input-field-form">
              <mat-form-field appearance="outline" floatLabel="always" class="input-field">
                <mat-label><span>*</span> Last Name</mat-label>
                <input matInput type="text" formControlName="referringAgentLastName" maxLength="30" />
                <mat-error
                  *ngIf="createReferralForm.get('referringAgentInformation')['controls']['referringAgentLastName'].errors?.required">
                  Please enter Last Name.
                </mat-error>
                <mat-error
                  *ngIf="createReferralForm.get('referringAgentInformation')['controls']['referringAgentLastName'].errors?.pattern">
                  Please use only letters, a space, and -' characters.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="input-field-form">
              <mat-form-field appearance="outline" floatLabel="always" class="input-field">
                <mat-label><span>*</span> Email</mat-label>
                <input matInput type="text" formControlName="referringAgentEmail" maxLength="50" />
                <mat-error>Please enter a valid email address.</mat-error>
              </mat-form-field>
            </div>
            <div class="input-field-form mobile-field">
              <mat-form-field appearance="outline" floatLabel="always" class="input-phone-field">
                <mat-label><span>*</span> Phone</mat-label>
                <input matInput type="text" formControlName="referringAgentPhone" phoneFormat maxlength="14" />
                <mat-error
                  *ngIf="createReferralForm.get('referringAgentInformation')['controls']['referringAgentPhone'].errors?.required">
                  Please enter Phone.</mat-error>
                <mat-error
                  *ngIf="!createReferralForm.get('referringAgentInformation')['controls']['referringAgentPhone'].errors?.required">
                  Please enter a valid Phone.</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" floatLabel="always" class="input-mobile-field">

                <mat-select
                  [value]="createReferralForm.get('referringAgentInformation')['controls']['referringAgentPhoneType'].value">
                  <mat-option value="mobile">Mobile</mat-option>
                  <mat-option value="business">Business</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>

<!-- <button class="btn-primary">Submit My Referral</button>

<div class="disclaimer-wrapper">
  Disclaimer: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
  veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
  commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit
  esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
  non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
</div> -->
