/* tslint:disable */
import { Injectable } from '@angular/core';
import { AppConfigService } from './app-config.service';

/**
 * Gets the global API services configuration
 */
@Injectable({
  providedIn: 'root'
})
export class ApiConfigService {

  /** Root URL for Candidate Process API operations */
  rootUrl: string;
  /** Root URL for Access Mgmt API operations */
  accessManagementRootUrl: string;

  /**
   * Initialize the rootURL
   */
  constructor(private readonly config: AppConfigService) {
    const apiConfig: { [key: string]: string | number } = this.config.getConfig('api');
    //this.rootUrl = apiConfig.protocol + '://' + apiConfig.host + ':' + apiConfig.port + apiConfig.base_url;
    this.rootUrl = apiConfig.protocol + '://' + apiConfig.host;

    const apiConfig2: { [key: string]: string | number } = this.config.getConfig('accessManagement');
    this.accessManagementRootUrl = apiConfig2.protocol + '://' + apiConfig2.host;
  }
}
