import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfigService } from '../../services/app-config.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private readonly router: Router,
    private readonly appConfig: AppConfigService
  ) { }

  ngOnInit() {
    sessionStorage.clear();
    const logoutURL = this.appConfig.getConfig('login');
    this.router.navigate(['/externalRedirect', { externalUrl: logoutURL }], {
      skipLocationChange: true
    });
  }

}
