import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { LoginService } from './core/services/login.service';

/** Base application component - builds the site structure */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  /** Component instantiation */
  constructor(
    private readonly cookieService: CookieService,
    public readonly router: Router,
    public loginSvc: LoginService
  ) { }

  /** Component Angular initialization life cycle hook */
  ngOnInit() {
    // const sessionCookie: string = this.cookieService.get('car-ses-tok');
    // if (sessionCookie == null || sessionCookie.length === 0) {
    //   this.logout();
    // }
    // this.isUserLoggedIn();
  }

  isUserLoggedIn() {
    // this.loginSvc.isUserLoggedIn().subscribe(loggedInUser => {
    //   console.log(`logged in user: ${JSON.stringify(loggedInUser)}`);

    // });
  }

  logout() {
    this.router.navigate(['logout']); // redirect to Login page
  }
}
