import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AgentDetails } from '../../models/AgentDetails';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() agentDetail: AgentDetails;
  fullName: string;
  agentInitials: string;
  @ViewChild('sidenav', { static: false }) sidenav: MatSidenav;
  constructor() { }

  ngOnInit() {
    this.fullName = `${this.agentDetail.agent.firstName} ${this.agentDetail.agent.lastName}`;
    this.agentInitials = this.agentDetail.agent.firstName[0] + this.agentDetail.agent.lastName[0]
  }
  close() {
    this.sidenav.close();
  }
}
