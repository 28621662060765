import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { stateList } from '../../../core/models/constants';
import { AgentDetails } from 'src/app/core/models/AgentDetails';
import { AgentDetailService } from 'src/app/core/services/agent-detail.service';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
  selector: 'app-create-referral',
  templateUrl: './create-referral.component.html',
  styleUrls: ['./create-referral.component.scss']
})
export class CreateReferralComponent implements OnInit {
  createReferralForm: FormGroup;
  /** stores states list */
  stateList = stateList;
  /** filtered destination states for Customer Information */
  filteredStatesForCustomerInformation: any;
  /** details of agent */
  agentDetails: AgentDetails;
  /** Event object that is emitted when an autocomplete option is selected. */
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) customerInfoAutoComplete: MatAutocompleteTrigger;
  /**
   * base constructor
   * @param createReferralFormBuilder form builder instance
   * @param currencyPipe currency pipe for formatting
   * @param agentDetailSvc agent details svc
   */
  constructor(private readonly createReferralFormBuilder: FormBuilder,
    private readonly currencyPipe: CurrencyPipe,
    private agentDetailSvc: AgentDetailService) { }

  ngOnInit(): void {
    this.agentDetailSvc.loggedInAgentDetail.subscribe((agent: AgentDetails) => {
      this.agentDetails = agent;
    });
    // Overall form for CreateReferral
    this.createReferralForm = this.createReferralFormBuilder.group({
      // Customer Information form properties
      customerInformation: this.createReferralFormBuilder.group({
        customerFirstName: ['', [Validators.required, Validators.maxLength(30), Validators.pattern(/^[a-zA-Z'-]+(\s[a-zA-Z'-]+)?$/)]],
        customerLastName: ['', [Validators.required, Validators.maxLength(30), Validators.pattern(/^[a-zA-Z'-]+(\s[a-zA-Z'-]+)?$/)]],
        customerEmail: ['', [Validators.required, Validators.maxLength(50), Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z0-9]{1}[a-zA-Z\-0-9]+[^-]\.)+[a-zA-Z]{2,}))$/)]],
        customerPhone: ['', [Validators.required]],
        customerPhoneType: ['mobile'],
        customerStreetAddress: ['', [Validators.required, Validators.maxLength(72)]],
        customerCity: ['', [Validators.required, Validators.maxLength(50)]],
        customerState: ['', [Validators.required]],
        customerZipCode: ['', [Validators.required]],
        customerPreference: ['', [Validators.required]]
      }),

      // Buying Details Form properties
      buyingDetails: this.createReferralFormBuilder.group({
        buyingCity: [],
        buyingState: [],
        withinRadius: [],
        minimumPrice: [],
        maximumPrice: [],
        numberOfBedrooms: [],
        numberOfBathrooms: [],
        houseHuntingStartDate: [],
        houseHuntingEndDate: [],
        buyingPropertyTypes: [],
        buyingAdditionalComments: []
      }),

      // Selling Details Form properties
      sellingDetails: this.createReferralFormBuilder.group({
        sellingAddress: [],
        sellingStreetAddress: [],
        sellingCity: [],
        sellingState: [],
        sellingZipCode: [],
        sellingPropertyType: [],
        sellingOwnershipType: [],
        sellingAdditionalComments: []
      }),

      // Referring agent form Information
      referringAgentInformation: this.createReferralFormBuilder.group({
        referringAgentFirstName: ['', [Validators.required, Validators.maxLength(30), Validators.pattern(/^[a-zA-Z'-]+(\s[a-zA-Z'-]+)?$/)]],
        referringAgentLastName: ['', [Validators.required, Validators.maxLength(30), Validators.pattern(/^[a-zA-Z'-]+(\s[a-zA-Z'-]+)?$/)]],
        referringAgentEmail: ['', [Validators.required, Validators.maxLength(50), Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z0-9]{1}[a-zA-Z\-0-9]+[^-]\.)+[a-zA-Z]{2,}))$/)]],
        referringAgentPhone: ['', [Validators.required, Validators.minLength(14)]],
        referringAgentPhoneType: ['mobile']
      }),

      // Referring agent form Information
      receivingAgentInformation: this.createReferralFormBuilder.group({
        receivingAgentFirstName: [],
        receivingAgentLastName: [],
        receivingAgentCompany: [],
        receivingAgentEmail: [],
        receivingAgentPhone: [],
        receivingAgentPhoneType: [],
        receivingAgentCity: [],
        receivingAgentState: [],
        receivingAgentZip: [],
        receivingAgentReferralFee: [],
        isPreferredAgent: [],
        isAgentContacted: [],
        isCustomerInfoProvided: []
      }),
    });

    this.filteredStatesForCustomerInformation = this.createReferralForm.get('customerInformation')['controls']['customerState'].valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterState(value))
      );

    this.createReferralForm.get('referringAgentInformation').patchValue({
      referringAgentFirstName: this.agentDetails.agent.firstName,
      referringAgentLastName: this.agentDetails.agent.lastName,
      referringAgentEmail: this.agentDetails.agent.emailAddress,
      referringAgentPhone: this.agentDetails.agent.phoneNumber,
      referringAgentPhoneType: 'mobile'
    });
  }

  validateCreateReferralForm(createReferralForm: FormGroup) {
    Object.keys(createReferralForm.controls).map((key: string) => {
      const controls = createReferralForm.get(key);
      if (controls instanceof FormGroup) {
        this.validateCreateReferralForm(controls);
      } else {
        //  this.formErrors[key]='';
        if (controls && !controls.valid && (controls.touched || controls.dirty)) {
          // const errors = this.validationMessages[key];
          controls.errors.map(err => {
            if (err) {
              //  this.formErrors[key] += errors[err] + '';
            }
          });
        }
      }
    });
  }
  /**
   * filter the states list
   * @param value input value
   */
  filterState(value: any) {
    const filterValue = value.toLowerCase();
    return this.stateList.filter(option => option.name.toLowerCase().includes(filterValue));
  }
  /**
   * update the customer preference
   * @param value input value
   */
  updateCustomerPreference(value: string): void {
    this.createReferralForm.get('customerInformation')['controls']['customerPreference'].setValue(value);
  }
  /**
   * opening or closing auto-complete panel
   * @param event input value
   */
  openCloseAutoCompletePanel(event: any) {
    event.stopPropagation();
    if (!this.customerInfoAutoComplete.autocomplete.isOpen) {
      this.customerInfoAutoComplete.openPanel();
    } else {
      this.customerInfoAutoComplete.closePanel();
    }
  }
}
