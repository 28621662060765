<div id="referral-container" class="referral container">
  <app-header [agentDetail]="agentDetail"></app-header>
  <div class="referral-container">
    <div class="referral-container-left-nav">
      <app-left-navigation [agentDetail]="agentDetail"></app-left-navigation>
    </div>
    <div class="referral-container-dashboard">
      <router-outlet></router-outlet>
      <app-footer></app-footer>
    </div>
  </div>
</div>
