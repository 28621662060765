import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[formControlName][zipFormat]',
})
export class ZipFormatDirective {
    private operation: string = '';
    constructor(public ngControl: NgControl) { }

    @HostListener('ngModelChange', ['$event'])
    onModelChange(event) {
        this.onInputChange(event);
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(event) {
        this.operation = event.key;
        this.onInputChange(event.target.value);
    }


    onInputChange(event) {
        let newVal = event.replace(/\D/g, '');
        newVal.length === 10 ? newVal = newVal.substring(0, 9) : null;
        if (event.length === 5 && newVal.length === 5) {
            this.ngControl.control.setErrors(null);
        } else if (event.length >= 10) {
            this.ngControl.control.setErrors(null);
        } else if (event.length !== 0) {
            this.ngControl.control.setErrors({ zipError: true });
        }

        if (newVal.length === 0) {
            newVal = '';
            this.ngControl.control.setErrors({ required: true });
        } else if (newVal.length <= 5) {
            newVal = newVal.replace(/^(\d{0,5})/, '$1');
        } else {
            newVal = newVal.replace(/^(\d{0,5})(\d{0,4})/, '$1-$2');
        }

        if (!(this.operation === 'Backspace' || this.operation === 'Delete')) {
            this.ngControl.valueAccessor.writeValue(newVal);
        }
    }
}
