export const referralSubmit = 'Submit Referral To';
export const referralDetails = 'Your Referral Details';
/** Title to display copyright*/
export const copyright = '&copy; 2000-2019 Cartus Corporation';
/** state list mock json */
export const stateList = [
    { 'name': 'Alabama', 'shortName': 'AL' },
    { 'name': 'Alaska', 'shortName': 'AK' },
    { 'name': 'Arizona', 'shortName': 'AZ' },
    { 'name': 'Arkansas', 'shortName': 'AR' },
    { 'name': 'California', 'shortName': 'CA' },
    { 'name': 'Colorado', 'shortName': 'CO' },
    { 'name': 'Connecticut', 'shortName': 'CT' },
    { 'name': 'Delaware', 'shortName': 'DE' },
    { 'name': 'District of Columbia', 'shortName': 'DC' },
    { 'name': 'Florida', 'shortName': 'FL' },
    { 'name': 'Georgia', 'shortName': 'GA' },
    { 'name': 'Hawaii', 'shortName': 'HI' },
    { 'name': 'Idaho', 'shortName': 'ID' },
    { 'name': 'Illinois', 'shortName': 'IL' },
    { 'name': 'Indiana', 'shortName': 'IN' },
    { 'name': 'Iowa', 'shortName': 'IA' },
    { 'name': 'Kansa', 'shortName': 'KS' },
    { 'name': 'Kentucky', 'shortName': 'KY' },
    { 'name': 'Lousiana', 'shortName': 'LA' },
    { 'name': 'Maine', 'shortName': 'ME' },
    { 'name': 'Maryland', 'shortName': 'MD' },
    { 'name': 'Massachusetts', 'shortName': 'MA' },
    { 'name': 'Michigan', 'shortName': 'MI' },
    { 'name': 'Minnesota', 'shortName': 'MN' },
    { 'name': 'Mississippi', 'shortName': 'MS' },
    { 'name': 'Missouri', 'shortName': 'MO' },
    { 'name': 'Montana', 'shortName': 'MT' },
    { 'name': 'Nebraska', 'shortName': 'NE' },
    { 'name': 'Nevada', 'shortName': 'NV' },
    { 'name': 'New Hampshire', 'shortName': 'NH' },
    { 'name': 'New Jersey', 'shortName': 'NJ' },
    { 'name': 'New Mexico', 'shortName': 'NM' },
    { 'name': 'New York', 'shortName': 'NY' },
    { 'name': 'North Carolina', 'shortName': 'NC' },
    { 'name': 'North Dakota', 'shortName': 'ND' },
    { 'name': 'Ohio', 'shortName': 'OH' },
    { 'name': 'Oklahoma', 'shortName': 'OK' },
    { 'name': 'Oregon', 'shortName': 'OR' },
    { 'name': 'Pennsylvania', 'shortName': 'PA' },
    { 'name': 'Rhode Island', 'shortName': 'RI' },
    { 'name': 'South Carolina', 'shortName': 'SC' },
    { 'name': 'South Dakota', 'shortName': 'SD' },
    { 'name': 'Tennessee', 'shortName': 'TN' },
    { 'name': 'Texas', 'shortName': 'TX' },
    { 'name': 'Utah', 'shortName': 'UT' },
    { 'name': 'Vermont', 'shortName': 'VT' },
    { 'name': 'Virginia', 'shortName': 'VA' },
    { 'name': 'Washington', 'shortName': 'WA' },
    { 'name': 'West Virginia', 'shortName': 'WV' },
    { 'name': 'Wisconsin', 'shortName': 'WI' },
    { 'name': 'Wyoming', 'shortName': 'WY' }
];
/** property types json */
export const propertyTypes = [
    {
        name: 'Single Family'
    },
    {
        name: 'Townhouse'
    },
    {
        name: 'New Construction'
    },
    {
        name: 'Condo / Co-Op',
    },
    {
        name: 'Lot / Land'
    },
    {
        name: 'Multi-Family'
    },
    {
        name: 'Mobile Home'
    }
];
/** radius dropdown values */
export const withinRadiusValues = [
    {
        name: '5 miles',
        value: 5
    },
    {
        name: '10 miles',
        value: 10
    },
    {
        name: '20 miles',
        value: 20
    },
    {
        name: '50+ miles',
        value: 50
    },
];
/** number of rooms list */
export const noOfRooms = [
    {
        name: '1+',
        value: 1
    }, {
        name: '2+',
        value: 2
    }, {
        name: '3+',
        value: 3
    }, {
        name: '4+',
        value: 4
    }, {
        name: '5+',
        value: 5
    },
];
/** receiving agent referral fee dropdown values */
export const referralFeeList = [
    {
        name: '10%',
        value: 10
    },
    {
        name: '15%',
        value: 15
    },
    {
        name: '25%',
        value: 20
    },
    {
        name: '25%',
        value: 25
    },
    {
        name: '30%',
        value: 30
    },
    {
        name: '35%',
        value: 35
    }
];
